<template>
  <div class="login_wrap" >
    <div class="login_bg"  >
      <div class="hello_text" >HELLO～</div>
      <div class="welcome_wrap" >
        <div class="welcome_text" >欢迎登录</div>
        <div class="name_content" >
          康域AI熏蒸舱
        </div>
      </div>
    </div>
    <div class="content" >
      <div class="form_item" >
        <input placeholder="请输入手机号" class="form_input" type="number" :maxlength="11" v-model="form.phone" />
      </div>

      <div class="form_item" >
        <input placeholder="请输入密码" class="form_input" type="password" v-model="form.password" />
      </div>

      <van-button class="login_button" :loading="submitting" @click="handleLogin" >登录</van-button>
    </div>
  </div>
</template>
<style scoped lang="less" >
@import "index";
</style>
<script>
import { handleAuth } from "@/utils/auth";
import { Toast } from 'vant'
import { login } from "@/api";

export default {
  name: 'login',
  data() {
    return {
      form: {
        phone: '',
        password: '',
        code: ''
      },
      submitting: false
    }
  },
  methods: {
    handleLogin() {
      const { form } = this
      if(!form.phone) {
        Toast('请输入手机号')
        return false
      }

      if(!form.phone) {
        Toast('请输入密码')
        return false
      }

      login({...form}).then(res => {
        console.log(res)
        if(res.success) {
          localStorage.setItem('token', res.data.token)
          window.location.reload()
        }else {
          Toast(res.msg)
        }
      })

    },
    geturlparam(name) {
      var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
      var r = window.location.search.substr(1).match(reg)
      if (r !== null) return unescape(r[2])
      return null
    }
  },
  mounted() {
    let code = this.geturlparam('code')
    this.form.code = code
    if(!code) {
      handleAuth()
    }
  }
}
</script>
